<template>
  <div class="enitity-placement">
    <div class="enitity-placement-inner">
      <div
        v-for="entity in getMLTextBlocks"
        :class="['enitity-placement-block', selectedStyle(entity.id)]"
        :style="{
          top: placement(entity.geometry.boundingBox).top,
          left: placement(entity.geometry.boundingBox).left,
          width: placement(entity.geometry.boundingBox).width,
          height: placement(entity.geometry.boundingBox).height,
          borderColor: getGreenToRed(entity.Confidence)
        }"
        :key="entity.id"
        @click="handleClick(entity, entity.id)"
      >
        <div class="enitity-placement-block-inner">
          <p>{{ entity.Text }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "EntityPlacement",
  data: () => ({
    shiftDown: false
  }),
  computed: {
    ...mapGetters([
      "getMLTextEntities",
      "getMLTextBlocks",
      "getMLSelectedTextEntities",
      "getMLSelectedTextEntitiesIndex",
      "getMLSelectedTextEntitiesIndexArr"
    ]),
    selectedEntity() {
      if (
        !this.getMLSelectedTextEntitiesIndex &&
        !this.getMLSelectedTextEntitiesIndexArr
      )
        return;
      return (
        this.getMLSelectedTextEntitiesIndex ||
        this.getMLSelectedTextEntitiesIndexArr
      );
    }
  },
  methods: {
    ...mapMutations(["setMLSelectedTextEntities"]),
    getEntityKey(object) {
      return Object.keys(object)[0];
    },
    selectedStyle(ind) {
      let selected = false;
      if (typeof this.selectedEntity == "object") {
        for (let i in this.selectedEntity) {
          if (this.selectedEntity[i] == ind) {
            selected = true;
            break;
          }
        }
      } else {
        selected = this.selectedEntity == ind;
      }

      return {
        "-focused": selected
      };
    },
    handleClick(entity, ind) {
      this.setMLSelectedTextEntities({
        entity,
        ind,
        shiftDown: this.shiftDown
      });
    },
    placement(geo) {
      const delta = 100
      return {
        top: `${geo.top * delta}%`,
        left: `${geo.left * delta}%`,
        width: `${geo.width * delta}%`,
        height: `${geo.height * delta}%`
      };
    },
    setShiftDown(event) {
      if (event.keyCode === 16 || event.charCode === 16) {
        this.shiftDown = true;
      }
    },
    setShiftUp(event) {
      if (event.keyCode === 16 || event.charCode === 16) {
        this.shiftDown = false;
      }
    }
  },
  mounted() {
    window.addEventListener
      ? document.addEventListener("keydown", this.setShiftDown)
      : document.attachEvent("keydown", this.setShiftDown);
    window.addEventListener
      ? document.addEventListener("keyup", this.setShiftUp)
      : document.attachEvent("keyup", this.setShiftUp);
  }
};
</script>
<style lang="scss" scoped>
$entColor: #6dd5fd;
$entHighlightColor: #ffc107;

.enitity-placement {
  position: absolute;
  top: 3px;
  left: 2px;
  width: 100%;
  height: 100%;
  position: all;
  &-confidence {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    background-color: red;
    padding: 5px;
    top: -14px;
    right: -12px;
    border-radius: 50%;
    p {
      color: white;
      font-size: 10px;
      font-weight: bold;
      margin: 0;
      padding: 0;
      opacity: 1 !important;
    }
  }
  &-inner {
    position: relative;
    width: inherit;
    height: inherit;
  }
  &-block {
    position: absolute;
    cursor: pointer;
    border: 0;
    outline-width: 0px;
    outline-color: darken($entColor, 20%);
    outline-style: solid;
    background-color: transparent;
    opacity: 0.5;
    z-index: 2;
    &-inner {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      span {
        padding: 5px;
        color: black;
        background-color: yellow;
        font-size: 10px;
        font-weight: bold;
        width: 100%;
      }
      p {
        opacity: 0;
      }
    }
    &.-focused {
      opacity: 0.6;
      outline-width: 2px;
      outline-color: darken($entHighlightColor, 20%);
      background-color: $entHighlightColor;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
      [class$="-inner"] {
        span {
          color: white;
          background-color: $black;
        }
      }
    }
  }
}
</style>
