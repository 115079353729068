<template>
  <div class="iframe-wrap">
    <iframe
      id="pdfIframe"
      :src="source"
      frameborder="0"
      scrolling="no"
      style="background: #FFFFFF;"
    ></iframe>
  </div>
</template>
<script>
export default {
  name: "IFrame",
  props: {
    source: {
      type: String,
      default: null
    }
  }
};
</script>
<style lang="scss" scoped>
.iframe-wrap {
  width: $iframeWidth;
  height: $iframeHeight;
  overflow: hidden;
  pointer-events: none;

  iframe {
    position: absolute;
    width: inherit;
    height: inherit;
    top: 0;
    left: 0;
    background-color: #fff;
  }
}
</style>
